import React, { useMemo } from 'react'
import Img from 'gatsby-image'
import nl2br from '../hooks/nl2br'

type Props = {
  texts: any[]
  images: any[]
}

const About = ({ texts, images }: Props): JSX.Element => {
  const aboutImg = useMemo(
    () =>
      images?.find((node) => node.data['Image_ID'] === 'about')?.data.Image
        .localFiles[0].childImageSharp.fluid,
    [images]
  )
  const aboutHtml = useMemo(
    () =>
      texts?.find((node) => node.data['Text_ID'] === 'start-1')?.data.Text
        .childMarkdownRemark.html,
    [texts]
  )

  return (
    <>
      <div className="d-none d-md-flex">
        <div className="col-6">
          {aboutHtml && (
            <div dangerouslySetInnerHTML={{ __html: nl2br(aboutHtml) }} />
          )}
        </div>
        <div className="col-6">
          <Img
            alt="Veronika"
            imgStyle={{ width: '100%', height: 'auto' }}
            className="w-100 h-auto"
            fluid={aboutImg}
          />
        </div>
      </div>
      <div className="d-md-none d-flex flex-column">
        <div className="col-12 mb-4">
          <Img
            alt="Veronika"
            imgStyle={{ width: '100%', height: 'auto' }}
            className="w-100 h-auto"
            fluid={aboutImg}
          />
        </div>
        <div className="col-12">
          {aboutHtml && (
            <div dangerouslySetInnerHTML={{ __html: nl2br(aboutHtml) }} />
          )}
        </div>
      </div>
    </>
  )
}

export default About
