import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Meta from '../components/Meta'
import Layout from '../components/Layout'
import PageSection from '../components/PageSection'
import SectionHeaderRow from '../components/SectionHeaderRow'

import About from '../components/About'
import InspirationForm from '../components/InspirationForm'
import ArtworkShop from '../components/ArtworkShop'

type Props = PageProps & {
  data: any
}

const IndexPage: React.FC<Props> = ({ data, location }: Props) => {
  console.log('IndexQuery: ', data)
  const { site, artworks, texts, images, inspirations, moods, prices } = data

  const fluidHeroImage = React.useMemo(
    () =>
      images?.nodes?.find((node) => node.data['Image_ID'] === 'header')?.data
        .Image.localFiles[0].childImageSharp.fluid,
    [images]
  )
  const publicHeroImage = React.useMemo(
    () =>
      images?.nodes?.find((node) => node.data['Image_ID'] === 'header')?.data
        .Image.localFiles[0].childImageSharp.pub.src,
    [images]
  )

  // const entryHtml2 = React.useMemo(
  //   () =>
  //     texts?.nodes?.find((node) => node.data['Text_ID'] === 'start-2')?.data
  //       .Text.childMarkdownRemark.html,
  //   [texts]
  // )
  // const entryHtml3 = React.useMemo(
  //   () =>
  //     texts?.nodes?.find((node) => node.data['Text_ID'] === 'start-3')?.data
  //       .Text.childMarkdownRemark.html,
  //   [texts]
  // )
  return (
    <Layout
      location={location}
      showHeader={true}
      showHero={true}
      publicHeroImage={publicHeroImage}
      fluidHeroImage={fluidHeroImage}
    >
      <Meta site={site?.meta} />
      <PageSection id="about">
        <SectionHeaderRow headline="Über mich" />
        <About texts={texts.nodes || []} images={images.nodes || []} />
      </PageSection>
      {/* <PageSection id="artworks">
        <SectionHeaderRow
          headline="Meine Kunstwerke"
          entryTextHtml={entryHtml2}
        />
        <ArtworkShop artworks={artworks.nodes || []} />
      </PageSection>
      <PageSection id="inspiration">
        <SectionHeaderRow
          headline="Dein persönliches Bild"
          entryTextHtml={entryHtml3}
        />
        <InspirationForm
          prices={prices.nodes || []}
          moods={moods.nodes || []}
          inspirations={inspirations.nodes || []}
        />
      </PageSection> */}
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
        adsense
      }
    }

    artworks: allAirtableArtworks {
      nodes {
        recordId
        data {
          Name
          Sold
          Description
          Size
          Price
          Technique
          Images {
            localFiles {
              id
              base
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    texts: allAirtableTexts {
      nodes {
        recordId
        data {
          Text_ID
          Text {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }

    images: allAirtableImages {
      nodes {
        recordId
        data {
          Image_ID
          Image {
            localFiles {
              publicURL
              childImageSharp {
                fluid(quality: 90, maxWidth: 4000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                pub: resize(toFormat: WEBP, webpQuality: 80, height: 1920) {
                  src
                }
              }
            }
          }
        }
      }
    }

    inspirations: allAirtableInspirations(
      sort: { order: ASC, fields: data___Name }
    ) {
      nodes {
        recordId
        data {
          Name
          Image {
            localFiles {
              publicURL
            }
          }
        }
      }
    }

    moods: allAirtableMoods {
      nodes {
        recordId
        data {
          Name
        }
      }
    }

    prices: allAirtablePrices {
      nodes {
        recordId
        data {
          Size
          Price
          Shipping_Costs
        }
      }
    }
  }
`
